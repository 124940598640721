<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">伤后多长时间可以进行伤残鉴定？</div>
            <p class="time">发布时间: 2017-05-01 中国法律服务网</p>
            <div class="space_content">
                《道路交通事故受伤人员伤残评定（GB18667-2002）》中第3.2条规定“评定时机应以事故直接所致的损伤或确因损伤所致的并发症治疗终结为准。对治疗终结意见不一致时，可由办案机关组织有关专业人员进行鉴定，确定其是否治疗终结。”《道路交通事故受伤人员伤残评定（GB18667-2002）》中第2.7条规定“治疗终结”是指“临床医学一般原则所承认的临床效果稳定。”由于损伤部位及性质不同，“治疗终结”的时间也不一样。具体如下:
                </div>
                <div class="space_content">
                1、以原发性损伤后果为依据评残的，在伤后1~3个月进行，如组织、器官、肢体缺损，器官切除、修补，颅骨、颌骨、肋骨缺损及牙齿脱落。
</div>
<div class="space_content">
2、对于影响容貌，遗留听力与视力障碍，组织器官畸形，脊柱骨折的在伤后3~6个月进行。
</div>
<div class="space_content">
3、对于遗留肢体功能障碍的宜在伤后6~9个月进行。
</div>
<div class="space_content">
4、对于颅脑损伤后的智力缺损与精神障碍，颅脑损伤致癫痫，语言功能障碍，大、小便失禁，性功能障碍，神经损伤致肢体瘫痪的宜在伤后6~12个月进行。对于伤者原有伤病，因事故而诱发或症状加重的，不应作为评定时机的限定条件。
</div>
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇:</p>
                    <router-link class="a" :to="'/news/industrynews/1'"><p class="ellipsis1">国家禁毒办：将整类合成大麻素类物质列入管制</p></router-link>
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇:</p>无
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/news/industrynews'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>